/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

import sprites from "../images/icons/sprites.svg";

const Icon = ({ href, className, fill }) => {
  return (
    <svg aria-hidden="true" className={className} focusable="false">
      <use href={sprites + `#${href}`} />
    </svg>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  href: PropTypes.string,
};

Icon.defaultProps = {
  fill: "#fff",
};

export default Icon;
