/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import React from "react";

import Footer from "./Footer";
import Header from "./Header";
import Hero from "./Hero";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <main>
        <Hero />
        {children}
      </main>
      <Footer />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
