/** @jsx jsx */
import { jsx, Heading, Container } from "theme-ui";

import background from "../images/NXL-hero-bg-full-words.jpg";
import mobileBackground from "../images/NXL-hero-bg-full-words-mobile.jpg";

const Hero = () => {
  return (
    <section
      sx={{
        backgroundImage: [
          `url(${mobileBackground})`,
          null,
          `url(${background}), linear-gradient(78.3deg, #7F26C4 0%, #64DFDF 98.9%)`,
        ],
        backgroundPosition: "center center",
        backgroundSize: "cover",
        color: "white",
        pb: [8, "800px", 7],
        pt: [5, 6, 7],
      }}
    >
      <Container>
        <Heading
          as="h1"
          variant="styles.h1"
          sx={{ maxWidth: ["100%", null, "50%"] }}
        >
          a brand new, next level experience is coming soon!
        </Heading>
      </Container>
    </section>
  );
};

export default Hero;
