/** @jsx jsx */
import { jsx, Container, Box } from "theme-ui";
// import { useEffect, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import Icon from "./Icon";

const Header = () => {
  // const [scrolled, setScrolled] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("scroll", function () {
  //     setScrolled(window.pageYOffset > 0);
  //   });
  // }, []);

  const scrolledNavStyles =
    // scrolled
    // ? {
    //     background: "linear-gradient(78.3deg, #7F26C4 0%, #64DFDF 98.9%)",
    //   }
    //   :
    {};

  const data = useStaticQuery(graphql`
    query headerLinks {
      site {
        siteMetadata {
          socialLinks {
            iconName
            name
            url
          }
        }
      }
    }
  `);

  return (
    <header
      sx={{
        alignItems: "center",
        background: "transparent",
        left: 0,
        position: "absolute",
        py: [3, 4],
        top: 0,
        width: "100%",
        ...scrolledNavStyles,
      }}
    >
      <Container>
        <div
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Link to="/">
            <Icon
              href="logo-white"
              sx={{
                height: ["27px", "56px"],
                verticalAlign: "middle",
                width: ["168px", "350px"],
              }}
            />
          </Link>

          <nav>
            <ul
              sx={{
                alignItems: "center",
                display: ["none", "flex"],
                flexWrap: "wrap",
                listStyleType: "none",
                my: 0,
                pl: 0,
                li: {
                  ":not(:first-of-type)": {
                    ml: 3,
                  },
                },
              }}
            >
              {data.site.siteMetadata.socialLinks.map((link, index) => (
                <li key={index}>
                  <a
                    href={link.url}
                    sx={{
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                  >
                    <Box as="span" variant="styles.srOnly">
                      {link.name}
                    </Box>

                    <Icon
                      href={link.iconName}
                      sx={{
                        height: "22px",
                        width: "22px",
                        verticalAlign: "middle",
                      }}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </Container>
    </header>
  );
};

export default Header;
