/** @jsx jsx */
import React, { useState } from "react";
import { jsx, Button, Input, Label, Flex } from "theme-ui";
import { useForm } from "react-hook-form";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import axios from "axios";
import { truncate } from "lodash";

const GDPR_NOTICE =
  "I agree to allow Next Level Recruiting to store and process my personal data.";

const MARKETING_NOTICE =
  "I agree to receive emails from Next Level Recruiting.";

const errorStyles = {
  padding: "0rem 2rem",
  margin: "0.5rem 0 1rem",
  color: "red",
  display: "block",
};

// console.log(process.env.GATSBY_HUBSPOT_URL);

const Form = ({ className }) => {
  const { register, handleSubmit, formState, reset } = useForm();
  const [success, setSuccess] = useState(false);
  const [formSubmissionError, setFormSubmissionError] = useState(false);

  const goBack = () => {
    setSuccess(false);
  };

  const cleanUp = (wasError = false) => {
    // var final_data = JSON.stringify(data);
    setSuccess(true);
    setFormSubmissionError(wasError);
    reset();
  };

  const onSubmit = (d) => {
    let keyPair = [];
    for (const property in d) {
      if (property === "name") {
        // keyPair.push({})
        const names = d[property].split(" ");
        let nameGroup = [
          {
            name: "firstname",
            value: names[0],
          },
          {
            name: "lastname",
            value: names[names.length - 1],
          },
        ];

        keyPair = keyPair.concat(nameGroup);
      } else if (property !== "receive-emails") {
        keyPair.push({
          name: property,
          value: d[property],
        });
      }
    }
    console.log("Form data:", keyPair);

    var data = {
      submittedAt: Date.now(),
      fields: keyPair,
      context: {
        // hutk: document.cookie.replace(
        //   /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
        //   "$1"
        // ), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: "www.example.com/page",
        pageName: "v3 Landing Page",
      },
      // legalConsentOptions: {
      //   // Include this object when GDPR options are enabled
      //   consent: {
      //     consentToProcess: true,
      //     text: "I agree to allow Next Level Recruiting to store and process my personal data.",
      //     communications: [
      //       {
      //         value: true,
      //         subscriptionTypeId: 999,
      //         text: MARKETING_NOTICE,
      //       },
      //     ],
      //   },
      // },
    };

    console.log("Prepped for sending data: ", {
      data,
      url: process.env.GATSBY_HUBSPOT_URL,
    });
    axios.post(process.env.GATSBY_HUBSPOT_URL, data).then(
      function (response) {
        console.log("Axios response: ", response);
        cleanUp();
      },
      (error) => {
        console.log("Axios error: ", error);
        cleanUp(true);
      }
    );

    // ;
  };

  const { errors } = formState;
  console.log("Success", { success, errors });
  if (success) {
    return (
      <div className={className}>
        {formSubmissionError ? (
          <div className={className}>
            <h1>There was an error with your submission. Try again later!</h1>
          </div>
        ) : (
          <h1>We'll keep you posted!</h1>
        )}
        <button
          onClick={goBack}
          sx={{
            border: "none",
            background: "transparent",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            svg: {
              transition: "all 0.1s",
            },
            ":hover": {
              svg: {
                marginLeft: -2,
                marginRight: 2,
              },
            },
          }}
        >
          <KeyboardBackspaceIcon />
          <span>Go back</span>
        </button>
      </div>
    );
  }

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <Label htmlFor="name" variant="styles.srOnly">
        First &amp; Last Name
      </Label>
      <Input
        type="text"
        placeholder="first &amp; last name*"
        id="name"
        aria-invalid={errors.name ? "true" : "false"}
        {...register("name", { required: "Name is required!" })}
        sx={{
          borderColor: errors.name ? "red" : null,
          ":focus": {
            // display: "block",
            // marginBottom: errors.name ? 3 : null,
          },
        }}
      />
      {errors.name && (
        <span role="alert" sx={{ ...errorStyles }}>
          {errors.name.message}
        </span>
      )}

      <Label htmlFor="email" variant="styles.srOnly">
        Email address
      </Label>
      <Input
        type="email"
        placeholder="email address*"
        sx={{ mt: 3, borderColor: errors.email ? "red" : null }}
        id="email"
        {...register("email", { required: "Email is required" })}
        aria-invalid={errors.email ? "true" : "false"}
      />
      {errors.email && (
        <span role="alert" sx={{ ...errorStyles }}>
          {errors.email.message}
        </span>
      )}

      <Label htmlFor="phone" variant="styles.srOnly">
        phone number
      </Label>
      <Input
        type="tel"
        placeholder="phone number"
        sx={{ mt: 3 }}
        {...register("phone")}
      />

      <Label htmlFor="graduation_year" variant="styles.srOnly">
        graduation year
      </Label>
      <Input
        placeholder="graduation year"
        sx={{ mt: 3 }}
        id="graduation_year"
        {...register("graduation_year")}
      />
      <span
        sx={{
          color: "text",
          display: "inline-block",
          fontFamily: "heading",
          fontSize: [0, 2],
          pl: 4,
          pt: 2,
          textTransform: "uppercase",
        }}
      >
        *Required field
      </span>

      <div sx={{ display: "flex", px: 4, mt: 2 }}>
        <input
          id="receive-emails"
          type="checkbox"
          {...register("receive-emails", {
            required: "You must agree to receive emails!",
          })}
        />
        <Flex sx={{ flexWrap: "wrap" }}>
          <Label
            htmlFor="receive-emails"
            variant="styles.h5"
            // sx={{ color: errors["receive-emails"] ? "red" : "inherit" }}
          >
            {MARKETING_NOTICE} *
          </Label>
          {errors["receive-emails"] && (
            <span role="alert" sx={{ ...errorStyles, m: 0, p: 0 }}>
              {errors["receive-emails"].message}
            </span>
          )}
        </Flex>
      </div>

      <div>
        <Button type="submit" variant="primary" sx={{ mt: 3 }}>
          submit
        </Button>
      </div>
    </form>
  );
};

export default Form;
