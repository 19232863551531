/** @jsx jsx */
import { jsx, Container, Themed } from "theme-ui";

import BackgroundDots from "../images/icons/background-dots.svg";
import Form from "../components/Form";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import useScript from "../hooks/useScript";

const IndexPage = () => {
  const [hubspt] = useScript({
    url: "//js.hs-scripts.com/20238422.js",
    id: "hs-script-loader",
  });

  return (
    <Layout>
      <Seo title="Coming Soon!" />

      <section
        sx={{
          backgroundImage: `url(${BackgroundDots}), linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 85%)`,
          backgroundPosition: "center, center",
          backgroundRepeat: "no-repeat, no-repeat",
          backgroundSize: "cover, cover",
        }}
      >
        <Container>
          <div sx={{ pt: [3, null, 5] }}>
            <Themed.h2 sx={{ my: 2 }}>Let Colleges Find You.</Themed.h2>
            <Themed.h4 sx={{ my: 2 }}>
              Join our student athlete recruiting community.
            </Themed.h4>
            <Themed.p sx={{ maxWidth: ["100%", null, "55%"] }}>
              We connect student athletes from all backgrounds with coaches,
              colleges and athletic scholarships. Sign up for more information
              and start your journey to the next level!
            </Themed.p>
          </div>

          <Form
            sx={{
              maxWidth: ["100%", "70%", "50%"],
              mt: [4, null, 6],
              pb: [5, null, 7],
              mx: "auto",
            }}
          />
        </Container>
      </section>
    </Layout>
  );
};

export default IndexPage;
