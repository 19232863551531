/** @jsx jsx */
import { jsx, Container, Box } from "theme-ui";
import { Link, useStaticQuery, graphql } from "gatsby";

import GradientWave from "../images/icons/gradient-wave.svg";
import MobileGradientWave from "../images/icons/gradient-wave-mobile.svg";
import Icon from "./Icon";

const Footer = ({ className }) => {
  const data = useStaticQuery(graphql`
    query footerLinks {
      site {
        siteMetadata {
          socialLinks {
            iconName
            name
            url
          }
        }
      }
    }
  `);

  return (
    <footer
      className={className}
      sx={{
        backgroundImage: [
          `url(${MobileGradientWave})`,
          null,
          `url(${GradientWave})`,
        ],
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        pt: ["150px", null, "250px", null, "400px"],
      }}
    >
      <Container>
        <div
          sx={{
            alignItems: "center",
            py: [4, null, 5],
            display: "flex",
            flexWrap: "wrap",
            justifyContent: ["center", null, "space-around"],
          }}
        >
          <div sx={{ flex: ["0 0 auto", null, "0 0 calc(100% / 3)"] }}>
            <Link
              to="/"
              sx={{
                cursor: "pointer",
                display: "inline-block",
                textAlign: ["center", null, "left"],
              }}
            >
              <Icon
                href="logo-large-white"
                sx={{
                  width: ["156px", null, "255px"],
                  height: ["69px", null, "113px"],
                }}
              />
            </Link>
          </div>

          <nav
            sx={{
              flex: ["0 0 100%", null, "0 0 calc(100% / 3)"],
              mt: [4, null, 0],
            }}
          >
            <h1
              sx={{
                color: "white",
                fontFamily: "body",
                fontSize: [3, null, 5],
                fontWeight: "bold",
                lineHeight: "100%",
                textAlign: ["center", null, "right"],
                textTransform: "uppercase",
              }}
            >
              stay in touch
            </h1>

            <ul
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: ["center", null, "flex-end"],
                listStyleType: "none",
                my: 0,
                pl: 0,
                li: {
                  ":not(:first-of-type)": {
                    ml: 3,
                  },
                },
              }}
            >
              {data.site.siteMetadata.socialLinks.map((link, index) => (
                <li key={index}>
                  <a
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    <Box as="span" variant="styles.srOnly">
                      {link.name}
                    </Box>

                    <Icon
                      href={link.iconName}
                      sx={{
                        height: "22px",
                        verticalAlign: "middle",
                        width: "22px",
                      }}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
