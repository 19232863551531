import { useEffect, useState } from "react";

const useScript = ({ url, id }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    script.id = id;

    document.body.appendChild(script);

    const handleLoad = () => {
      setLoaded(true);
    };

    script.addEventListener("load", handleLoad);

    return () => {
      script.removeEventListener("load", handleLoad);
      document.body.removeChild(script);
    };
  }, [url, id]);

  return [loaded];
};

export default useScript;
